.login-modal-container{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.504);
  z-index:1;
}


.login-modal {
  opacity: 1;
  position: absolute;
  top: 0;
  right: 120px;
  height: 80%;
  width: 400px;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;

  background: #ffffff;
  border: 1px #dcdcdc solid;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;

}

.login-welcome {
  color: #6b80e3;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 700;
  padding: 30px 60px;
}

.login-welcome-subtext {
  /* Sign into your account */

  font-weight: 700;
  font-size: .8em;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #84848a;
}
.login-form {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.login-input {
  height: 50px;
  width: 90%;
  text-indent: 20px;
  text-align: left;
  /* padding-left: 50px; */
  opacity: 0.7;
  transition: 0.3s;
  background: #edf2f7;
  border-bottom: 1px solid #6b7fe5;

  font-weight: bold;
  font-size: .8em;
  line-height: 33px;

  color: rgba(0, 0, 0, 0.7);
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.login-input:hover {
  transform: scale(1.05);
}
.login-input:active,
.login-input:focus {
  background: #5e95e2;
  color: white;
}

.login-input:active::placeholder,
.login-input:focus::placeholder {
  color: rgb(255, 255, 255);
}

.login-submit {
  height: 60px;
  width: 90%;
  text-align: center;
  font-size: 1em;
  background: #6b7fe5;
  color: white;
  font-weight: 700;
  /* margin-top: 30px; */
  cursor: pointer;
}

.forgot-password {
  /* I forgot my password */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #939596;
  padding: 20px 0;
  cursor: pointer;
}

.register-new-account {
  border: 1px solid #77797C;;
  background: transparent;
  box-sizing: border-box;
  padding: 10px 0;
  width: 90%;

  margin-top: 40px;
  /* Register New Account */

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;

  color: rgba(0, 0, 0, 0.5);
  z-index: 10;

}


.error-message-container{
  background: tomato;
  /* border-radius: 30px; */
  /* height: 100px; */
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.error-top{
  display: none;
  font-size: 28px;
  color: white;
}

.error-bottom{
  font-size: 18px;
  color: white;
  text-align: center;
}

.make-an-account{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  font-size: 18px;
  color: red;
  /* width: 100%; */
  
}

.help-email-container{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}
.help-message{
  font-size: .75rem;
}
.help-email{
  padding: 0 3px;
  font-size: 1rem;
}

.modal__close-login{
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .login-modal {
    /* position: absolute;
    top: 10%;
    right: 0px;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 15px 0; */
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh + env(safe-area-inset-top) + 150px);
    padding: 15px 0;

  }
  .login-welcome{
    padding: 10px ;
    font-size: 2rem;
  }
  .login-form{
    padding-top: 20px;
  }

}