.analytics-container{
    padding: 20px 20px 0 0;
}
.graph-containers{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-right: 20px;
    gap: 20px;
}

div#bar-chart-container, #line-chart-container{
    background: white;
    border-radius: 10px;
}

.barchart-mark{
    /* fill: rgb(241, 104, 104); */
}

.line-group{
    fill: #c4c4c4;
}

.scorecards-container{
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    gap: 20px;
}

.scorecard-container{
    width: 120px;
    /* height: 75px; */
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.scorecard-value{
    font-size: 20px;
    
}
.scorecard-name{
    font-size: 12px;
}