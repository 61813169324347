.new-grid-goal-input-form {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.all-inputs-container {
  padding: 50px 0;
  height: auto;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  /* padding: 50px 0; */
  opacity: 1;
  transition: 0.3s;
}
.active-input-container {
  opacity: 1;
  transition: 0.3s;
}
.prompt-container {
  display: flex;
  gap: 5px;
  padding-left: calc(20% - 60px);
}
.prompt-number {
  font-size: 1.3rem;
}
.prompt-description {
  font-size: 1.1rem;
}

.input-row-container {
  display: flex;
  gap: 20px;
  height: 60px;
  width: 100%;
  padding: 10px 0 50px;
  align-items: center;
  justify-content: center;
}
.main-input {
  height: 100%;
  width: 60%;
  text-indent: 20px;
  color: #273f45;
  font-size: 1rem;
  background: linear-gradient(#4d7e8a, #4d7e8a) center bottom 10px /
    calc(100% - 40px) 2px no-repeat;
  /* background-color: #85e4f7; */
  border-radius: 10px;
  position: relative;
}

.next-container {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ok-button {
  width: 88px;
  height: 38px;
  color: #ffffff;
  background: #2e71e5;
  border-radius: 10px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.ok-button.ok-button-create-goal{
  width: 128px;
}

.press-enter-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.indicator-row-container {
  padding-top: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.indicatior-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.indicatior-container.active-indicator .indicator {
  width: 139px;
  height: 26px;
}

.indicator {
  width: 126px;
  height: 18px;
}

.indicatior-container.active-indicator .blue-background.indicator {
  box-shadow: 0px 0px 10px 3px #9be2f4;
}
.indicatior-container.active-indicator .red-background.indicator {
  box-shadow: 0px 0px 10px 3px #e67571;
}
.indicatior-container.active-indicator .green-background.indicator {
  box-shadow: 0px 0px 10px 3px #9be2f4;
}
.indicatior-container.active-indicator .yellow-background.indicator {
  box-shadow: 0px 0px 10px 3px #fff98e;
}

.blue-underline {
  background: linear-gradient(#4d7e8a, #4d7e8a) center bottom 10px /
    calc(100% - 40px) 2px no-repeat;
}
.red-underline {
  background: linear-gradient(rgba(255, 240, 239, 1), rgba(255, 240, 239, 1))
    center bottom 10px / calc(100% - 40px) 2px no-repeat;
}
.green-underline {
  background: linear-gradient(rgba(61, 101, 54, 1), rgba(61, 101, 54, 1)) center
    bottom 10px / calc(100% - 40px) 2px no-repeat;
}
.orange-underline {
  background: linear-gradient(#ffbe7b, #ffbe7b) center bottom 10px /
    calc(100% - 40px) 2px no-repeat;
}

.yellow-underline {
  background: linear-gradient(rgba(75, 73, 44, 1), rgba(75, 73, 44, 1)) center
    bottom 10px / calc(100% - 40px) 2px no-repeat;
}

.blue-background,
.blue-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4d7e8a;
  font-size: 1rem;
  background-color: #9be2f4;
}

.red-background,
.red-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  font-size: 1rem;
  background-color: #e67571;
  color: rgba(255, 240, 239, 1);
}

.green-background,
.green-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  font-size: 1rem;
  background-color: #98f898;
  color: rgba(61, 101, 54, 1);
}

.yellow-background,
.yellow-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  font-size: 1rem;
  background-color: #fff98e;
  color: rgba(75, 73, 44, 1);
}

.orange-background,
.orange-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  font-size: 1rem;
  background-color: #ffbe7b;
  color: rgb(38, 38, 38);
}
.grey-background,
.grey-background::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  font-size: 1rem;
  background-color: #343434;
  color: #ddd;
}

.indicator-title {
  color: #6b6b6b;
  font-size: 1.25rem;
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

.example-elements-container {
  padding: 0px 0 20px calc(20% - 60px);
}
.example-title {
  font-size: 1.5rem;
}

.example-elements {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.example-element {
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.example-element.blue {
  background: #9be2f4;
  color: #4d7e8a;
}
.example-element.red {
  background-color: #e67571;
  color: rgba(255, 240, 239, 1);
}
.example-element.green {
  background-color: #98f898;
  color: rgba(61, 101, 54, 1);
}
.example-element.yellow {
  background-color: #fff98e;
  color: rgba(75, 73, 44, 1);
}

.submit-button-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}
.submit-button-container input.submit-button {
  width: 50%;
  color: white;
  font-size: 28px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 800;
  border-radius: 8px;
  background: #515151;
  border: 1px solid #ddd;
  text-transform: uppercase;
}

.submit-button-container input.submit-button:hover {
  background-color: #343434;
  border-radius: 50px;
  transition: 0.3s;
  /* color: rgb(43, 42, 42); */
}

.preview-text{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.top-text{
  display: inline-flex;
  padding: 5px;
  border-radius: 5px;
}

.top-text.blue{
  background: #9be2f4;
  color: #a0adb1;
}
.top-text.red{
  background-color: #e67571;
color: rgba(255, 240, 239, 1);
}
.top-text.green{
  background-color: #98f898;
color: rgba(61, 101, 54, 1);
}
.top-text.yellow{
  background-color: #fff98e;
color: rgba(75, 73, 44, 1);
}
@media screen and (max-width: 768px) {
  .input-container {
    padding: 0 0;
  }

}

@media screen and (max-width: 600px) {
  .input-row-container {
    flex-direction: column;
    height: 70px;
  }
  .main-input {
    height: 60px;
    width: 100%;
  }
  .all-inputs-container {
    /* height: 200px; */
  }
  .next-container {
    flex-direction: row;
    width: 150px;
  }
  .example-elements-container {
    display: none;
  }
}

/* New Outcome */

.image-fieldset label {
  border: 0;
  cursor: pointer;
  filter: grayscale(100%);
}

.image-fieldset label:hover {
  filter: grayscale(0);
}

.image-fieldset input[type="radio"]:checked + label {
  filter: grayscale(0);
}

.image-fieldset label img {
  width: 60px;
}

fieldset.image-fieldset {
  /* margin: 0 auto; */
  min-width: 0;
  padding: 0;

  border: 0 none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* padding-bottom: 30px; */
  width: 60%;
  /* max-width: 500px; */
}

input.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
