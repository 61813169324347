.mobile-navbar {
  display: none;
}
.desktop-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-icons{
  display: flex;
}

.burger-container {
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 130px;
  top: 20px;
}

.burger-bar1,
.burger-bar2,
.burger-bar3 {
  width: 35px;
  height: 5px;
  background-color: #69a0e5;
  margin: 6px 0;
  transition: 0.4s;
}

.burger-change .burger-bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.burger-change .burger-bar2 {
  opacity: 0;
}

.burger-change .burger-bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.mobile-nav-container {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile-navbar{
    display: flex;
    z-index: 10;
  }
  .close-mobile-nav{
      display: none;
  }

  .desktop-navbar {
    display: none;
  }
  .open-mobile-nav {
    display: flex;
  }

  .mobile-nav-container {
      /* display: flex; */
    /* display: inline; */
    position: relative;
    flex-flow: column nowrap;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    padding-top: 3.5rem;
  }

  .nav-action-mobile{}
  .nav-actions-mobile{
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    justify-content: center;
    align-items: center;
    /* padding: 20px 50px; */
    /* max-width: 400px; */
  }
  .mobile-error-message{
    padding: 10px;
    position: absolute;
    width: auto;
    word-wrap: normal;
    box-shadow: 1px 1px 20px #aaa;
    border-radius: 5px;
    background-color: #fff;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%);
    transform-style: preserve-3d;
    z-index: 200;
    font-size: 1rem;
    color: #D30423;
    box-shadow: 1px 1px 20px rgba(211, 4, 35, 0.3);
    width: 80%;
    max-width: 300px;
    display: none;
    }

    .mobile-navbar .nav-links{
      flex-direction: column;
      font-size: 2rem;
      gap: 20px;
      padding: 20px 50px;
      max-width: unset;
      width: unset;
    }
  
    .mobile-navbar .gridgoal-link-tooltip, 
    .mobile-navbar .gridgoal-link-tooltip-analytics, 
    .mobile-navbar .gridgoal-link-tooltip-accountability {
      display: none !important;
    }
    
    .mobile-navbar .gridgoal-link-tooltip:after, 
    .mobile-navbar .gridgoal-link-tooltip-analytics:after, 
    .mobile-navbar .gridgoal-link-tooltip-accountability:after  {
      content: "";
   
    }
    .mobile-navbar .gridgoal-link-tooltip:before, 
    .mobile-navbar .gridgoal-link-tooltip-analytics:before, 
    .mobile-navbar .gridgoal-link-tooltip-accountability:before {
      content: "";
    }
  /* .open-mobile-nav{
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  .close-mobile-nav{
    transform: translateX(100px);
    transition: transform 0.3s ease-in-out;
  } */
}
