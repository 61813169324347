/* Modal */

.modal__wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  opacity: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 100;
}

.modal__overflow-wrapper {
  align-items: center;

  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal--visible {
  opacity: 1;
  visibility: visible;
}

.modal__window {
  margin: 4rem;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

.modal__content,
.modal__close {
  border-radius: 10px;
}

.modal__close {
  background-color: white;
  display: flex;
  height: 2.5rem;
  left: 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal__close--icon {
  padding-right: 0.25rem;
  width: 2.25rem;
}

.modal__content {
  background-color: white;
  max-height: 100vh;
  max-width: 80rem;
  overflow: auto;
  padding: 2.25rem;
  position: relative;

  width: 80%;
  height: 80%;
}

[data-modal-event],
.modal__close {
  cursor: pointer;
}
/* Applied to body on modal open */

.no-scroll--modal {
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .modal__window {
    margin: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .modal__window,
  .modal__content {
    max-height: calc(100vh - 4rem);
  }
  .modal__window {
    margin: 4rem 0 0;
  }
}

.new-goal-form {
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  gap: 20px;
  max-width: 70%;
  margin: 0 auto;
}

.text-input {
}

.new-goal-form {
  width: 100%;
  display: inline-block;
}

.new-goal-form input,
.new-goal-form select {
  width: 100%;
  font-size: 14px;
  vertical-align: middle;
  margin: 15px 0px 5px 0px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.new-goal-form button {

}

.new-goal-form button:hover {
  background-color: #5d94e2
}

.submit-button-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}
.new-goal-form input.submit-button {
  width         : 50%;
  color         : white;
  font-size     : 28px;
  cursor        : pointer;
  transition    : 0.3s;
  font-weight   : 800;
  border-radius : 8px;
  background    : #80b1bf;
  border        : 1px solid #ddd;
  text-transform: uppercase;
}

.new-goal-form input.submit-button:hover {
  background-color: #9dd8e9;
  border-radius: 50px;
  transition: 0.3s;
  /* color: rgb(43, 42, 42); */
}

.preview-text {
  padding-top: 10px;
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.preview-text-per-basis{
  padding-top: 10px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.example-goals {
  padding-top: 30px;
}
.example-container {
  height: 26px;
  display: flex;
  padding: 5px 100px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  margin: 4px;
}
/* .example-container:nth-child(4n+1){
  background:rgb(122, 224, 33);
}
.example-container:nth-child(4n+2){
  background:rgb(224, 106, 33);
}
.example-container:nth-child(4n+3){
  background:rgb(33, 224, 218);
}
.example-container:nth-child(4n+4){
  background:rgb(224, 62, 33);
} */
.example-container:hover {
  background: #5d94e244;
  border-radius: 10px;
  transition: 0.3s;
}

.example-goal-title {
  display: flex;
  justify-content: flex-start;
  padding: 5px 100px;
  align-items: center;
  /* border-bottom: 3px solid #5d94e2; */
  padding-bottom: 16px;
}

.example-goal-title div:nth-child(1){
  width: 50%;
}
.example-goal-title div {
  font-size: 1.6rem;
}


.example-grid-goal {
  display: flex;
  gap: 5px;
}

.blue-text{background: #85e4f7 !important; border-radius: 5px; padding: 5px;}
.red-text{background: rgb(246, 109, 109) !important; border-radius: 5px; padding: 5px;}
.green-text{background: #98f898 !important; border-radius: 5px; padding: 5px;}
.yellow-text{background: #fff98e !important; border-radius: 5px; padding: 5px;}
.clear-text{ border-radius: 5px; padding: 5px 0;}