/* Remove padding and margin */
:root {
  --blue-text: #5d94e2;
  --grey-text: #9ca9bb;
  --grey-dark-text: #747474;
  --red-bar: #934747;
  --green-bar: #479355;

  --cell-width: 5px;
  --cell-font-size: 12px;

  --completion-width: 0;
  --time-width: 0;
}
* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
  border: 0;
}

html,
body,
div#root {
  height: 100%;
  background: #e5e5e5;
}

.App {
  background: #e5e5e5;
  /* width: 100vw; */
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* NAV BAR BEGIN */
.navbar {
  height: 75px;
  display: flex;

  background-color: white;
  align-items: center;
  justify-content: space-between;
}
.navbar a {
  text-decoration: none;
}

.navbar .logo {
  margin: auto 30px;
  font-size: 2rem;
  color: #699ee7;
  max-width: 200px;
  width: 200px;
  cursor: pointer;
}
.rest-of-navbar {
  /* width: 75vw; */
}

.gridgoal-link {
  position: relative;
  color: #0c1521;
  cursor: not-allowed;
}

/* .gridgoal-link-tooltip{
  display: none;
  width: 200px;
  position: absolute;
  top: 200%;
  left: -50px;
  p
  padding: 20px;
  background: white;
  border: black 1px solid;
  border-radius: 10px;
} */

/* .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
} */

/* .gridgoal-link-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -50px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
} */

.gridgoal-link-tooltip,
.gridgoal-link-tooltip-analytics,
.gridgoal-link-tooltip-accountability {
  padding: 10px;
  position: absolute;
  width: auto;
  white-space: nowrap;
  word-wrap: no-wrap;
  box-shadow: 1px 1px 20px #aaa;
  border-radius: 5px;
  background-color: #fff;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  transform-style: preserve-3d;
  z-index: 200;
  font-size: 0.9em;
  display: none;
  color: #d30423;
  box-shadow: 1px 1px 20px rgba(211, 4, 35, 0.3);
}
.gridgoal-link-tooltip:after,
.gridgoal-link-tooltip-analytics:after,
.gridgoal-link-tooltip-accountability:after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateX(-50%);
  background-color: #fff;
  left: 50%;
  top: -1px;
  z-index: 400;
}
.gridgoal-link-tooltip:before,
.gridgoal-link-tooltip-analytics:before,
.gridgoal-link-tooltip-accountability:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateX(-50%) translateZ(-1px);
  background-color: #fff;
  left: 50%;
  top: 0px;
  z-index: -1;
  box-shadow: -1px -1px 20px #aaa;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
  gap: 30px;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #699fe6;
}

.user-profile,
.login-icon {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  max-width: 50px !important;
  margin-right: 50px;
  cursor: pointer;
  aspect-ratio: 1;
}

.nav-action {
  width: 150px;
  min-width: 150px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 5px;
}

.sign-up {
  border: #699fe6 1px solid;
  background: white;
  color: #699fe6;
}
.sign-in {
  background: #699fe6;
  color: white;
}

.user-profile {
  background-color: #454545;
}

.login-icon {
  background-color: #e16552;
}
/* NAV BAR END */

/* ACTIVE GRID GOAL ELEMENT BEGIN */
.active-grid-goal-container {
  background-color: white;
  width: 350px;
  border-radius: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.3s;
}
.active-grid-goal-container:hover {
  background-color: rgb(236, 235, 235);
  transition: 0.3s;
}
.active-grid-goal-container.empty {
  height: 166px;
  border: dashed black 1px;
  background: #f6f6f6;
  height: 16vh;
  transition: 0.3s;
}

.active-grid-goal-container.empty:hover {
  background: #e0e0e0;
  transition: 0.3s;
}
.add-new-gridgoal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-new-gridgoal img {
  width: 50px;
  height: 50px;
}

.grid-goal-header {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px 15px;
}
.name-date-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
}
.grid-goal-toprow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.grid-goal-name {
  color: var(--blue-text);
  font-size: 1em;
  font-weight: 700;
}
.grid-goal-due-date {
  color: var(--grey-text);
  font-size: 0.8em;
  font-weight: 700;
}
.grid-goal-due-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid-goal-timers {
}

.grid-goal-icon img {
  height: 50px;
}
.grid-goal-icon {
  font-size: 18px;
}
.grid-goal-progress-bars-container {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;

  font-size: 0.8em;
}
.progress-container {
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
}
.time-remaining-text {
  color: var(--grey-text);
  font-weight: 500;
}
.time-remaining-bar {
  height: 5px;
  width: var(--time-width);
  background-color: var(--red-bar);
  transition: 0.5s;
}
.completed-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.completed-out-of {
  color: var(--grey-dark-text);
  font-weight: 700;
}
.completed-percent {
  color: var(--grey-dark-text);
  font-weight: 700;
}
.completed-bar {
  height: 5px;
  width: var(--completion-width);
  background-color: var(--green-bar);
  transition: 0.5s;
}

.grid-goal-delete img {
  width: 20px;
  height: 20px;
  opacity: 0.1;
  cursor: pointer;
}
.grid-goal-delete img:hover {
  opacity: 1;
  transition: 0.5s;
}

/* ACTIVE GRID GOAL ELEMENT END */

/* PAGE CONTENT BEGIN */
.page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
  position: relative;
  padding-left: 30px;
  /* height: 100%; */
}
.titles-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px 0 10px;
  gap: 30px;
}
.current-outcome-title {
  font-size: 1.7rem;
  color: var(--grey-dark-text);
  text-align: center;
}
/* PAGE CONTENT END */

/* ACTIVE GRID GOALS CONTAINER BEGIN */
.active-grid-goals-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  padding-top: 20px;
}

/* ACTIVE GRID GOALS CONTAINER END */

/* GRID GOAL CONTAINER BEGIN */
.grid-goal-container {
  margin: 20px 20px 0 20px;
  width: auto;
  height: auto;
  background: white;
  /* max-height: 85vh; */
  border-radius: 10px;
  /* height: calc(100% - 20px); */
}

.grid-goal-header-container {
  height: 100px;
  /* background: darkgoldenrod; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.grid-goal-title {
  font-size: 1.7vw;
  font-weight: 400;
  color: var(--grey-dark-text);
}
form.form-inline {
  width: 100%;
}
.add-amount-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  font-size: 20px;
  background: white;
  border-bottom: var(--grey-dark-text) solid 3px;
  color: var(--grey-dark-text);
}
.plus-icon {
}

.grid-goal-body-content-container {
  margin: 20px 30px;

  /* max-height: 70vh; */
  /* overflow-y: scroll; */
  /* height: 100%; */
}
.grid-goal-body-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  height: 100%;
  align-content: flex-start;
  justify-content: center;
}
.grid-goal-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

/* GRID GOAL CONTAINER END */

/* CELL BEGIN*/
.grid-goal-cell {
  width: var(--cell-width);
  height: var(--cell-width);
  background: rgb(235, 235, 235);
  border-radius: 1px;
  font-size: var(--cell-font-size);
  text-align: center;
  line-height: var(--cell-width);
  /* inline-size: calc(var(--cell-width) - 2px); */
  /* overflow-wrap: break-word; */
  transition: 0.5s;
  border-radius: 4px;
}

.active-grid-cell {
  background: #9bffff;
  transition: 0.5s;
}

.partial-fill {
  position: absolute;
  height: calc(var(--cell-width));
  z-index: -1;
}

:root {
  /* --red-cell: #934747;
  --orange-cell: #af6749;
  --yellow-cell:#cdc320;
  --green-cell:#42a931;
  --light-blue-cell:#1fb0d5;
  --dark-blue-cell:#2449cd;
  --purple-cell:#811fcf; */

  --red-cell: #e67571;
  --orange-cell: #feb99c;
  --yellow-cell: #fef89c;
  --green-cell: #aef5a2;
  --light-blue-cell: #9be2f4;
  --dark-blue-cell: #8ba4fe;
  --purple-cell: #cb8bfe;
}

/*   
  background-color: ;
 
  background-color: ;
  
  background-color: ;

  background-color: 
 
  background-color: 
 
  background-color: 

  background-color:*/

.active-grid-cell-0 {
  background-color: var(--red-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-1 {
  background-color: var(--orange-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-2 {
  background-color: var(--yellow-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-3 {
  background-color: var(--green-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-4 {
  background-color: var(--light-blue-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-5 {
  background-color: var(--dark-blue-cell);
  /* color: white; */
  font-weight: 600;
}
.active-grid-cell-6 {
  background-color: var(--purple-cell);
  /* color: white; */
  font-weight: 600;
}

/* CELL END*/

/* FORM BEGIN*/
.add-amount-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
input:focus,
textarea {
  outline: none !important;
}

.form-inline input.text-input {
  width: 100%;
  font-size: 16px;
  line-height: 24px;

  margin: 5px 0px 5px 0px;
  padding: 10px;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  text-align: right;
}

.form-inline .plus-icon {
  background-image: url("./assets/add-amount.png");
  background-color: white;

  cursor: pointer;

  height: 30px;
  width: 36px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* FORM END */

.mobile-grid-goal-nav{
  display: none;
}

@media only screen and (max-width: 768px) {
  .page-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .mobile-grid-goal-nav{
    background: #747474;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15vw;
    height: 75px;
    width: 100%;
  }
  .mobile-nav-item-container{
    height: 60px!important;
    width: 100px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .mobile-nav-item-container.active{
    background: #5c5b5b;
  }
  
  .mobile-grid-goal-nav img{
    

    height: 40px;
    
  }
  .active-grid-goals-container {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .active-grid-goals-container.active{
    display: flex;
  }


  .grid-goal-header-container {
    flex-direction: column;
    padding-top: 10px;
    height: 150px;
    justify-content: center;
  }
  .grid-goal-container {
    display: none;
    margin: 20px 0 0 0;
    width: 95%;
    max-height: unset;
  }
  .grid-goal-container.active{
    display: unset;
  }

  .analytics-container{
    display: none;
  } 
  .analytics-container.active{
    display: unset;
}

  .grid-goal-progress-bars-container {
    display: none;
  }
  .active-grid-goal-container.empty {
    height: 6vh;
  }
  div.grid-goal-name {
    width: 200px !important;
  }
  .grid-goal-header {
    padding: 5px;
  }
  .active-grid-goal-container {
    padding: 0;
    width: 90%;
  }
  .name-date-container {
    justify-content: center;
  }
  .grid-goal-icon {
    display: flex;
  }

  .grid-goal-icon img {
    width: 50px;
    height: 50px;
  }
  .add-new-gridgoal img {
    height: 30px;
    width: 30px;
  }
  .active-grid-goals-container {
    padding-left: 0;
    gap: 10px;
  }
  .grid-goal-header-container {
    padding-left: 3px;
    padding-right: 3px;
    height: auto;
  }
  .grid-goal-title {
    font-size: 1.5rem;
  }

  form.form-inline {
    width: 100%;
  }
  .add-amount-container {
    padding-top: 10px;
    width: 100%;
  }
  .grid-goal-body-content-container {
    margin: 10px 0;
    width: 100%;
  }
}
