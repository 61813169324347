.outcome-page {
  display: flex;
  position: relative;
}
.page-title {
  font-size: 2rem;
  border-bottom: 2px rgb(94, 94, 94) solid;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.outcome-container-wrapper-examples {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  background: grey;

  color: white;
  padding-top: 15px;
  visibility: visible;
  transition: .3s;
  z-index: 1000;
  height: calc(100vh - 90px);
}

.outcome-container-wrapper-examples.collapsed-examples{
  width: 30px !important;
  transition: .3s;
}

.collapsed-examples .outcome-arrow{
  position: absolute;
  transform: rotateZ(270deg);
  cursor: pointer;
  right: 0px;

}
.outcome-container-wrapper-examples.collapsed-examples .outcome-arrow{
  visibility: visible;

}
.outcome-container-wrapper-examples.collapsed-examples .example-outcomes-title,
.outcome-container-wrapper-examples.collapsed-examples .example-outcome{
  white-space: nowrap;
  overflow: hidden;
  visibility: hidden;
}


.example-outcomes-title {
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;

  padding-bottom: 10px;

}

.outcome-arrow{
  position: absolute;
  transform: rotateZ(270deg);
  cursor: pointer;
  top: 20px;
  right: 5px;
}

.example-outcome-duration{
  color: rgb(212, 212, 212);
}

.outcome-arrow svg{
  fill: rgb(195, 194, 194);
}

.outcome-container-wrapper {
  padding: 10px;
  width: 700px;
  max-width: 1440px;
  min-width: 300px !important;
  margin: 0 auto;
}

.example-outcome {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}
.example-outcome:hover{
  color: black;
}

.example-outcome:hover .example-outcome-duration{
  color: black;
}
.example-outcome:hover:nth-child(7n+1){
  background: #E67571;
}
.example-outcome:hover:nth-child(7n+2){
  background: #FEB99C;
}
.example-outcome:hover:nth-child(7n+3){
  background: #FEF89C;
}
.example-outcome:hover:nth-child(7n+4){
  background: #AEF5A2;
}
.example-outcome:hover:nth-child(7n+5){
  background: #9BE2F4;
}
.example-outcome:hover:nth-child(7n+6){
  background: #8BA4FE;
}
.example-outcome:hover:nth-child(7n+7){
  background: #CB8BFE;
}

.left-column-example{
  display: flex;
  gap: 10px;
}
.example-outcome-image{
  width: 20px;
}

.new-outcome {
  height: 100px;
  display: flex;
  align-items: center;
  background: #fffdfd;
  gap: 20px;
  border-radius: 10px;
  border: dashed black 1px;
  cursor: pointer;
  margin-top: 10px;
  padding-left: 50px;
}

.new-outcome-image {
  height: 50%;
}
.new-outcome-text {
  font-size: 1.5rem;
}

.outcome-containers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.outcome-container {
  font-size: 1.5rem;
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #fffdfd;
  gap: 0px;
  border-radius: 10px;
  /* border: solid black 1px; */
  justify-content: space-between;
  transform: 0.4s;
  cursor: pointer;
  gap: 10px;
}

.outcome-container:hover {
  background: rgb(217, 217, 217);
  transform: 0.4s;
}
.output-left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.output-right {
  display: flex;
  align-items: center;
  gap: 50px;
  padding-right: 50px;
}
.outcome-image {
  height: 50px;
}
.outcome-start {
}
.outcome-title {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.outcome-component {
  width: 150px;
}
.outcome-name {
  font-size: 15px;
}
.outcome-content {
}

.dropdown-outcome {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .page-title {
    font-size: 1.6rem;
    padding-bottom: 10px;
  }
  .outcome-container {
    height: 100px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 60px;
    gap: 5px;
    justify-content: center;
    transform: 0.4s;
  }

  .output-left {
    max-width: 250px;
  }
  .output-left .dropdown-outcome {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(270deg);
    transform: 0.4s;
  }
  .output-right .grid-goal-delete {
    position: absolute;
    right: 20px;
    top: 30px;
    transform: translateY(-50%);
    transform: 0.4s;
  }
  .outcome-title {
    font-size: 1.3rem;
  }

  .new-outcome.open-modal-added {
    padding-left: 20px;
    gap: 20px;
  }
  .outcome-container-wrapper {
    padding: 10px 10px 10px 50px;
    
  }

  .new-outcome-text {
    font-size: 1.2rem;
  }
  
}
